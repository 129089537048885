<!--
 * @Author       : JiangChao
 * @Date         : 2022-10-11 08:53:45
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-05 23:27:22
 * @Description  : 支付结果web
-->
<template>
    <div class="container">
        <div v-if="this.type == 'success'">
            <img :src="require('@/assets/imgs/png/tubiao-ok.png')" />
            <div style="text-align: center">支付成功</div>
        </div>
        <div v-if="this.type == 'fail'">
            <img :src="require('@/assets/imgs/png/fail-images.png')" />
            <div style="text-align: center">支付失敗</div>
        </div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";
import { Util } from "@/utils/utils";

export default {
    name: "",
    data() {
        return {
            apiParams: {},
            type: "",
            transactionId: "",
            orderId: "",
        };
    },
    methods: {
        async getLinepayInfo() {
            try {
                const res = await this.$axios.get(
                    `linePay/saveKeyLine?transactionId=${this.transactionId}&orderId=${this.orderId}`
                );
                if (res.data.success) {
                    // const token = localStorage.getItem("token");
                    const token = window.$cookies.get("token");

                    if (token) {
                        this.$axios.defaults.headers.common["token"] = token;
                        this.$axios.defaults.headers.common["appType"] =
                            "PHONE";
                    }
                    this.$router.push("/map");
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {},
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
        this.apiParams = Util.UrlSearch();
        this.type = this.apiParams.type;
        // this.toast("url所有的参数："+JSON.stringify(this.apiParams)+"type---"+this.type);
        this.transactionId = this.$route.query.transactionId;
        this.orderId = this.$route.query.orderId;
        if (this.type === "success") {
            this.getLinepayInfo();
        }
    },
    created() {},
};
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
p {
    font-size: 20px;
}

img {
    margin-top: 100px;

    width: 100px;
    height: 100px;
}
</style>
