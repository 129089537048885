/*
 * @Author: jiangchao
 * @Date: 2021-01-06 10:24:59
 * @LastEditTime : 2022-07-22 13:30:34
 * @LastEditors  : JiangChao
 * @Description: 工具类
 * @FilePath: /ybt-h5/src/utils/utils.js
 */

export const Util = {

    UrlSearch() {
        // let url = "http://192.168.31.219:8089/#/InviteFriends/?recommend=0912345678";
        // let url = "http://192.168.31.219:8089/#/PayResult?type=success";

        let url = window.location.href;
        let obj = {};
        let reg = /[?&][^?&]+=[^?&]+/g;
        let arr = url.match(reg);
        if (arr) {
            arr.forEach((item) => {
                let tempArr = item.substring(1).split("=");
                let key = decodeURIComponent(tempArr[0]);
                let val = decodeURIComponent(tempArr[1]);
                obj[key] = val;
            });
        }
        return obj;
    },

}